import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';

import './cases_home.css';

const API_URL = process.env.REACT_APP_API_URL;
const CLOUDFRONT_URL = process.env.REACT_APP_CLOUDFRONT_LINK;

function CasesHome() {
    const [videoFiles, setVideoFiles] = useState([]);
    const [originalVideoFiles, setOriginalVideoFiles] = useState([]);
    const [surgeonInfo, setSurgeonInfo] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(1);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true); // Initialize loading to true
    const observer = useRef();
    const authHeader = useAuthHeader();
    const navigate = useNavigate();
    const videoIdSet = useRef(new Set());
    const [annotatedVideos, setAnnotatedVideos] = useState({});
    const [privateVideos, setPrivateVideos] = useState({});

    const [annotatedFilter, setAnnotatedFilter] = useState(false);

    const [videoTypeFilter, setVideoTypeFilter] = useState('all'); // Default is 'all'

    const [accessFilter, setAccessFilter] = useState('all'); // 'all' by default
    const [genderFilter, setGenderFilter] = useState(''); // Default is no filter

    const [showVideoTypeFilter, setShowVideoTypeFilter] = useState(true);
    const [showAccessFilter, setShowAccessFilter] = useState(true);
    const [showGenderFilter, setShowGenderFilter] = useState(true);
    const [showSearch, setShowSearch] = useState(true);  // For search section
    const [showFilters, setShowFilters] = useState(true);  // For filters section
    const [searchInput, setSearchInput] = useState('');



    useEffect(() => {
        const fetchVideoData = async () => {
            try {
                setLoading(true);
                const token = authHeader.split(' ')[1];
                const response = await axios.get(
                    `${API_URL}/video/accessible`,
                    { headers: { 'Authorization': `Bearer ${token}` } }
                );

                const annotatedVideosResponse = await axios.get(
                    `${API_URL}/meta_annotation/annotatedVideos`,
                    { headers: { 'Authorization': `Bearer ${token}` } }
                );

                const privateVideosResponse = await axios.get(
                    `${API_URL}/video/private`,
                    { headers: { 'Authorization': `Bearer ${token}` } }
                );

                const annotatedVideosData = annotatedVideosResponse.data.reduce((acc, video) => {
                    acc[video.videoId] = video;
                    return acc;
                }, {});

                const privateVideosData = privateVideosResponse.data.reduce((acc, video) => {
                    acc[video.videoId] = video;
                    return acc;
                }, {});

                setAnnotatedVideos(annotatedVideosData);
                setPrivateVideos(privateVideosData);






      // Fetch the video metadata and status
      const videoDataPromises = response.data.map(async (video) => {
        // Fetch the video status from the status route
            const statusResponse = await axios.get(
                `${API_URL}/video/${video.videoId}/status`,
                { headers: { 'Authorization': `Bearer ${token}` } }
                );

                // Return video object including the status
                return {
                videoId: video.videoId,
                videoTitle: video.videoTitle,
                videoDescription: video.videoDescription,
                surgeryType: video.surgeryType,
                surgerySpeciality: video.surgerySpeciality,
                uploadedBy: video.uploadedBy,
                thumbnailUrl: `${CLOUDFRONT_URL}/videos/${video.videoId}/Thumbnails/${video.videoId}_thumbnail.0000000.jpg`,
                status: statusResponse.data.status // Add status to the video metadata
                };
            });

            // Wait for all promises and resolve video data
            const videoData = await Promise.all(videoDataPromises);
            setOriginalVideoFiles(videoData); // Store original video list
            setVideoFiles(videoData); // Set the video files including status



                
            } catch (error) {
                setError('Error fetching video data');
            } finally {
                setLoading(false); // Set loading to false after fetch completes
            }
        };
        fetchVideoData();
    }, [authHeader, page]);

    useEffect(() => {
        const token = authHeader.split(' ')[1];

        const fetchSurgeonDetails = async () => {
            const infoPromises = videoFiles
                .filter(video => video.uploadedBy)
                .map(video =>
                    axios.all([
                        axios.get(`${API_URL}/user/name/${video.uploadedBy}`, {
                            headers: { 'Authorization': `Bearer ${token}` }
                        }).then(response => ({ videoId: video.videoId, surgName: response.data.name }))
                            .catch(() => ({ videoId: video.videoId, surgName: 'Unknown' })),

                        axios.get(`${API_URL}/user/${video.uploadedBy}/organisation`, {
                            headers: { 'Authorization': `Bearer ${token}` }
                        }).then(response => ({ videoId: video.videoId, orgName: response.data.organisation }))
                            .catch(() => ({ videoId: video.videoId, orgName: 'Unknown' }))
                    ])
                );

            const combinedResponses = await Promise.all(infoPromises);

            const surgeonDetails = combinedResponses.map(([nameResponse, organisationResponse]) => ({
                videoId: nameResponse.videoId,
                surgName: nameResponse.surgName,
                orgName: organisationResponse.orgName,
            }));

            setSurgeonInfo(prevDetails => ([...prevDetails, ...surgeonDetails]));
        };

        if (videoFiles.length > 0) {
            fetchSurgeonDetails();
        }
    }, [videoFiles, authHeader]);

    const lastVideoElementRef = useRef();


    // prob like for infinite scrolling
    useEffect(() => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && !loading) {
                setPage(prevPage => prevPage + 1);
            }
        });

        if (lastVideoElementRef.current) observer.current.observe(lastVideoElementRef.current);
    }, [loading]);

    

    const handleDelete = async (videoId) => {
        try {
            const token = authHeader.split(' ')[1];

            await axios.delete(`${API_URL}/video/${videoId}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });

            await axios.delete(`${API_URL}/storage/delete/${videoId}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });

            videoIdSet.current.delete(videoId);
            setVideoFiles(prevFiles => prevFiles.filter(video => video.videoId !== videoId));

        } catch (error) {
            console.error('Error deleting video:', error);
        }
    };

    const handleUploadClick = () => {
        navigate('/upload_video');
    };

    const handleSearchClick = () => {
        setSearchTerm(searchInput.trim());
    };
    


    const handleResetFilters = () => {
        setPage(1);
        setAccessFilter('all');
        setAnnotatedFilter(false);
        setGenderFilter('');
        setVideoTypeFilter('all');
        setSearchInput('');
        setSearchTerm('');
    };



    const applyFilters = async () => {
        try {
            setLoading(true);
            const token = authHeader.split(' ')[1];
            let fetchedVideos = [];

            if (searchTerm.trim() !== '') {
                // Fetch search results
                const response = await axios.get(`${API_URL}/video/search`, {
                    headers: { 'Authorization': `Bearer ${token}` },
                    params: { searchTerm },
                });

                fetchedVideos = response.data || [];
            } else {
                // Handle video type filtering
                if (videoTypeFilter === 'uploads') {
                    const uploadsResponse = await axios.get(`${API_URL}/user/uploads`, {
                        headers: { 'Authorization': `Bearer ${token}` },
                    });

                    fetchedVideos = uploadsResponse.data?.uploadedVideos || [];
                } else if (videoTypeFilter === 'starred') {
                    const starredResponse = await axios.get(`${API_URL}/user/starred`, {
                        headers: { 'Authorization': `Bearer ${token}` },
                    });

                    fetchedVideos = starredResponse.data?.starredVideos || [];
                } else if (videoTypeFilter === 'annotations') {
                    // Fetch annotated video IDs
                    const annotationsResponse = await axios.get(`${API_URL}/meta_annotation/annotatedVideos`, {
                        headers: { 'Authorization': `Bearer ${token}` },
                    });

                    const annotatedVideoIds = annotationsResponse.data?.map(video => video.videoId) || [];

                    // Fetch accessible videos and filter them by annotatedVideoIds
                    const accessibleResponse = await axios.get(`${API_URL}/video/accessible`, {
                        headers: { 'Authorization': `Bearer ${token}` },
                    });

                    const accessibleVideos = accessibleResponse.data || [];

                    fetchedVideos = accessibleVideos.filter(video => annotatedVideoIds.includes(video.videoId));
                } else {
                    // Default behavior: Fetch accessible videos
                    const accessibleResponse = await axios.get(`${API_URL}/video/accessible`, {
                        headers: { 'Authorization': `Bearer ${token}` },
                    });

                    fetchedVideos = accessibleResponse.data || [];
                }
            }

            // Apply access filter
            if (accessFilter === 'public') {
                fetchedVideos = fetchedVideos.filter(video => video.isPublic);
            } else if (accessFilter === 'private') {
                fetchedVideos = fetchedVideos.filter(video => !video.isPublic);
            }

            // Apply gender filter
            if (genderFilter) {
                const genderResponse = await axios.get(`${API_URL}/video/gender`, {
                    headers: { 'Authorization': `Bearer ${token}` },
                    params: { gender: genderFilter },
                });
                const genderVideos = genderResponse.data || [];

                fetchedVideos = fetchedVideos.filter(video =>
                    genderVideos.some(genderVideo => genderVideo.videoId === video.videoId)
                );
            }

            // Apply annotated filter
            if (annotatedFilter) {
                fetchedVideos = fetchedVideos.filter(video => annotatedVideos[video.videoId]);
            }

            // Fetch statuses and add thumbnail URLs
            const videoDataPromises = fetchedVideos.map(async (video) => {
                const statusResponse = await axios.get(
                    `${API_URL}/video/${video.videoId}/status`,
                    { headers: { 'Authorization': `Bearer ${token}` } }
                );

                return {
                    ...video,
                    status: statusResponse.data.status,
                    thumbnailUrl: `${CLOUDFRONT_URL}/videos/${video.videoId}/Thumbnails/${video.videoId}_thumbnail.0000000.jpg`,
                };
            });

            const videosWithStatus = await Promise.all(videoDataPromises);

            setVideoFiles(videosWithStatus);
        } catch (error) {
            console.error('Error fetching filtered videos:', error);
            setError('Error fetching filtered videos');
        } finally {
            setLoading(false);
        }
    };

    
    

    
    

    useEffect(() => {
        applyFilters();
    }, [annotatedFilter, accessFilter, genderFilter, videoTypeFilter, searchTerm]);

    const toggleFilterSection = (section) => {
        switch (section) {
            case 'videoType':
                setShowVideoTypeFilter(prev => !prev);
                break;
            case 'access':
                setShowAccessFilter(prev => !prev);
                break;
            case 'gender':
                setShowGenderFilter(prev => !prev);
                break;
            default:
                break;
        }
    };

    const toggleSection = (section) => {
        if (section === 'search') {
            setShowSearch(!showSearch);  // Toggle search section visibility
        } else if (section === 'filters') {
            setShowFilters(!showFilters);  // Toggle filters section visibility
        }
    };

    

    return (
        <div className="ch-cases-home-container">
            <div className="ch-filterbar-container">
                {/* Upload Button */}
                <button onClick={handleUploadClick} className="ch-upload-button">Upload</button>
    
                {/* Toggleable Search Section */}
                <div className="search-section">
                    <label className="section-title" onClick={() => toggleSection('search')}>
                        Search <span className="toggle-symbol">{showSearch ? '⊖' : '⊕'}</span>
                    </label>
                    {showSearch && (
                        <div className="search-bar-container">
                            <input
                                className="search-input"
                                type="text"
                                placeholder="Search videos..."
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearchClick();
                                    }
                                }}
                            />
                            <button className="search-button" onClick={handleSearchClick}>🔍</button>
                        </div>
                    )}
                </div>
    
                {/* Toggleable Filters Section */}
                <div className="filter-section">
                <label className="section-title" onClick={() => toggleSection('filters')}>
                    Filters <span className="toggle-symbol">{showFilters ? '⊖' : '⊕'}</span>
                </label>
                    {showFilters && (
                        <div className="ch-filters">
                            {/* Video Type Filter Section */}
                            <div className="filter-group">
                            <label className="filter-label" onClick={() => toggleFilterSection('videoType')}>
                                Library <span className="toggle-symbol">{showVideoTypeFilter ? '⊖' : '⊕'}</span>
                            </label>
                                {showVideoTypeFilter && (
                                    <div className="video-type-filter-container">
                                        <button
                                            className={`video-type-filter-button ${videoTypeFilter === 'all' ? 'active' : ''}`}
                                            onClick={() => setVideoTypeFilter('all')}
                                        >
                                            All
                                        </button>
                                        <button
                                            className={`video-type-filter-button ${videoTypeFilter === 'uploads' ? 'active' : ''}`}
                                            onClick={() => setVideoTypeFilter('uploads')}
                                        >
                                            Uploaded
                                        </button>
                                        <button
                                            className={`video-type-filter-button ${videoTypeFilter === 'starred' ? 'active' : ''}`}
                                            onClick={() => setVideoTypeFilter('starred')}
                                        >
                                            Starred
                                        </button>
                                        <button
                                            className={`video-type-filter-button ${videoTypeFilter === 'annotations' ? 'active' : ''}`}
                                            onClick={() => setVideoTypeFilter('annotations')}
                                        >
                                            Annotated
                                        </button>
                                    </div>
                                )}
                            </div>
    
                            {/* Access Filter Section */}
                            <div className="filter-group">
                            <label className="filter-label" onClick={() => toggleFilterSection('access')}>
                                Privacy <span className="toggle-symbol">{showAccessFilter ? '⊖' : '⊕'}</span>
                            </label>
                                {showAccessFilter && (
                                    <div className="access-filter-container">
                                        <button
                                            className={`access-filter-button ${accessFilter === 'all' ? 'active' : ''}`}
                                            onClick={() => setAccessFilter('all')}
                                        >
                                            All
                                        </button>
                                        <button
                                            className={`access-filter-button ${accessFilter === 'public' ? 'active' : ''}`}
                                            onClick={() => setAccessFilter('public')}
                                        >
                                            Public
                                        </button>
                                        <button
                                            className={`access-filter-button ${accessFilter === 'private' ? 'active' : ''}`}
                                            onClick={() => setAccessFilter('private')}
                                        >
                                            Private
                                        </button>
                                    </div>
                                )}
                            </div>
    
                            {/* Gender Filter Section */}
                            <div className="filter-group">
                            <label className="filter-label" onClick={() => toggleFilterSection('gender')}>
                                Patient Gender <span className="toggle-symbol">{showGenderFilter ? '⊖' : '⊕'}</span>
                            </label>  
                                {showGenderFilter && (
                                    <div className="gender-filter-container">
                                        <button
                                            className={`gender-filter-button ${genderFilter === '' ? 'active' : ''}`}
                                            onClick={() => setGenderFilter('')}
                                        >
                                            All
                                        </button>
                                        <button
                                            className={`gender-filter-button ${genderFilter === 'male' ? 'active' : ''}`}
                                            onClick={() => setGenderFilter('male')}
                                        >
                                            Male
                                        </button>
                                        <button
                                            className={`gender-filter-button ${genderFilter === 'female' ? 'active' : ''}`}
                                            onClick={() => setGenderFilter('female')}
                                        >
                                            Female
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
    
                {/* Apply/Reset Filter Buttons */}
                <div className="filter-actions">
                    <button onClick={handleResetFilters} className="reset-filters-button">Reset Filters</button>
                </div>
            </div>
    
            {/* Video Grid */}
            <div className="ch-cases-home-video-grid">
                {loading ? (
                    <div className="loader-wrapper">
                        <div className="loader_cv"></div> {/* Centered loader in video section */}
                    </div>
                ) : (
                    <>
                        {videoFiles.length === 0 && !loading && (
                            <div className="no-videos-container">
                                <div className="no-videos-icon">📹</div>
                                <div className="no-videos-text">No videos found</div>
                                <div className="no-videos-suggestion">
                                    Try uploading a video or adjusting your filters.
                                </div>
                            </div>
                        )}
{
  videoFiles
    .filter(video => video.status === 'complete') // Only display videos with status 'complete'
    .map((video, index) => (
        <Link to={`/cases_video/${video.videoId}`} key={index} className="ch-editor-container-link">
        <div className="ch-editor-container">
          <div className="ch-video-thumbnail">
            <img
              src={video.thumbnailUrl}
              alt={`${video.videoTitle} thumbnail`}
              className="thumbnail-image"
              onError={(e) => {
                e.target.style.display = 'none';
                e.target.onerror = null;
              }}
            />
          </div>
          <div className="ch-text-container">
            <div className="video-title">{video.videoTitle}</div>
            <div className="video-description">{video.videoDescription}</div>
            <div className="surgery-info">
              <span className="surgeon-name surgeon-info-box">
                {surgeonInfo.find(info => info.videoId === video.videoId)?.surgName || '...'}
              </span>
              <span className="surgery-separator"></span>
              <span className="surgeon-name surgeon-info-box">
                {surgeonInfo.find(info => info.videoId === video.videoId)?.orgName || '...'}
              </span>
              <span className="surgery-separator"></span>
              <span className="surgery-gen-info surgeon-info-box">{video.surgerySpeciality}</span>
              <span className="surgery-separator"></span>
              <span className="surgery-gen-info surgeon-info-box">{video.surgeryType}</span>
            </div>
          </div>
          {/*<button
            className="ch-delete-button"
            onClick={(e) => {
              e.preventDefault();
              handleDelete(video.videoId);
            }}
          >
            &#x2716;
        </button>*/}
        </div>
      </Link>
    ))
}

                    </>
                )}
            </div>
        </div>
    );
    
    
}

export default CasesHome;